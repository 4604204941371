import { $, offset, scrollTop as scrollTopFn } from "dom7";
import { isIOS } from "main/javascripts/utils/DeviceUtil";

$.fn.offset = offset;
$.fn.scrollTop = scrollTopFn;

const scrollToInputDelta = 8;

export function scrollBottom(id: string): void {
  const element: any = document.getElementById(id);
  if (element) {
    element.scrollTop = documentHeight(element);
  }
}
export function scrollTop(id: string): void {
  const element: any = document.getElementById(id);
  if (element) {
    element.scrollTop = 0;
  }
}
export function documentHeight(el: HTMLElement | null): any {
  const body: HTMLElement = document.body;
  const element: HTMLElement = el || document.documentElement;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    element.clientHeight,
    element.scrollHeight,
    element.offsetHeight
  );
}
export function height(id: string): any {
  const element: any = document.getElementById(id);
  if (element) {
    return Math.max(
      element.clientHeight,
      element.scrollHeight,
      element.offsetHeight
    );
  }
}

function getScrollElement(): any {
  let element: any;
  if ("scrollingElement" in document) {
    element = document.scrollingElement;
  } else if (navigator.userAgent.indexOf("WebKit") !== -1) {
    element = (<Document>document).body; // Fallback for legacy browsers
  } else {
    element = (<Document>document).documentElement;
  }
  return element;
}
export function scrollTo(
  to: number,
  duration: number,
  id: string | null = null
): void {
  let element: any = getScrollElement();

  if (id) {
    element = document.getElementById(id);
  }
  if (duration <= 0 || !element) {
    return;
  }

  const interval = 16;
  const start: number = element.scrollTop;
  const change: number = to - start;

  const animateScroll: any = (currentTime: number): any => {
    const elapsedTime: number = currentTime + interval;
    const position: number = easeInOut(elapsedTime, start, change, duration);

    setTimeout(() => {
      if (element) {
        if (elapsedTime > duration) {
          element.scrollTop = start + change;
          return;
        }
        element.scrollTop = position;
        animateScroll(elapsedTime);
      }
    }, interval);
  };

  animateScroll(0);
}

export function scrollToElement(
  elm: any,
  duration: number,
  offset: number = 0
): void {
  const scrollElement: any = getScrollElement();
  if (elm) {
    const elTop: number =
      Number($(elm).offset().top) +
      Number($(scrollElement).scrollTop()) -
      offset;
    scrollTo(elTop, duration);
  }
}

export function scrollToInputElement(elm: any, duration: number): void {
  if (elm) {
    if (Number($(elm).offset().top) > scrollToInputDelta || !isIOS()) {
      scrollToElement(elm, duration);
    }
  }
}

function easeInOut(
  currentTime: number,
  start: number,
  change: number,
  duration: number
): number {
  let t: number = currentTime / (duration / 2);
  if (t < 1) {
    return (change / 2) * t * t + start;
  }
  t -= 1;
  return (-change / 2) * (t * (t - 2) - 1) + start;
}

let bodyScrollTop = 0;
export function stopBodyScrolling(bool: boolean): void {
  if (bool) {
    bodyScrollTop = window.pageYOffset;
    Object.assign(document.body.style, {
      height: "100%",
      width: "100%",
      position: "fixed",
      top: `-${bodyScrollTop}px`,
      overflow: "hidden",
    });
  } else {
    const element: any = getScrollElement();
    const bodyPosition = document.body.style.position;
    Object.assign(document.body.style, {
      height: "",
      width: "",
      position: "",
      top: "",
      overflow: "",
    });
    if (bodyPosition === "fixed") {
      element.scrollTop = bodyScrollTop;
    }
  }
}
