export interface IMaxWidth {
  page1col: string;
  page1colWide: string;
  page2col: string;
}

export const maxWidth: IMaxWidth = {
  page1col: "720px",
  page1colWide: "840px",
  page2col: "1024px",
};
