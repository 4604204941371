import * as React from "react";
import { css } from "@emotion/react";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";

export interface INoticeProps {
  children: React.ReactNode;
  styles?: any;
}

export const Notice: React.FC<INoticeProps> = (
  props: INoticeProps
): React.ReactElement<INoticeProps> => {
  const { styles, children } = props;
  return <div css={[textStyle, styles]}>{children}</div>;
};

const textStyle = css`
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption3};
  padding: 0.125rem 0 ${space.atom};
  color: ${accentColor.secondaryColor};
`;
