import * as React from "react";
import { css } from "@emotion/react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import {
  backgroundColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";

interface IProps extends React.AllHTMLAttributes<HTMLSelectElement> {
  children: any;
  validate?: RegisterOptions;
  styles?: any;
  borderStyleKey?: keyof IBorderStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
}

export const Select: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const {
    name,
    children,
    styles = {},
    validate,
    borderStyleKey = "default",
    borderColorStyleKey = "none",
    ...rest
  } = props;
  const { exBlockStyle, exSelectStyle } = styles;
  const { register } = useFormContext();
  return (
    <div css={[blockStyle, exBlockStyle]}>
      <select
        css={[
          selectStyle,
          selectBorderStyle[borderStyleKey],
          selectBorderColorStyle[borderColorStyleKey],
          exSelectStyle,
        ]}
        {...register(name, validate)}
        {...rest}
      >
        {children}
      </select>
    </div>
  );
};

export interface IBorderStyle {
  default: any;
  noRound: any;
}

const blockStyle = css`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: ${space.atom1_5x};
    width: 0.5rem;
    height: 0.5rem;
    border: 0.125rem solid ${borderColor.secondaryLightColor};
    border-top: 0;
    border-left: 0;
    transform: translateY(-70%) rotate(45deg);
  }
`;
const selectStyle = css`
  font-size: ${fontSize.form};
  line-height: ${lineHeight.body1};
  font-weight: ${fontWeight.regular};
  color: ${textColor.primaryDarkColor};
  padding: 0.125rem calc(${space.atom3x} + 0.5rem) 0 ${space.atom1_5x};
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 40px;
  appearance: none;
  background-color: #fff;
  &:focus {
    outline: none;
  }
`;

const selectBorderBaseStyle = css`
  border: 1px solid ${backgroundColor.primaryLightColor};
  border-radius: ${borderRadius.normal};
`;
const selectBorderStyle: IBorderStyle = {
  default: css`
    ${selectBorderBaseStyle};
  `,
  noRound: css`
    ${selectBorderBaseStyle};
    border-radius: 0;
  `,
};

const selectBorderColorStyle: IBorderColorStyle = {
  none: css`
    border-color: white;
  `,
  primary: css`
    border-color: ${borderColor.primaryDarkColor};
  `,
  secondary: css`
    border-color: ${borderColor.secondaryLightColor};
  `,
};
